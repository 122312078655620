import React, { useContext, useEffect, useState } from "react";
import Seo from "../components/seo";
import CustomSelect from "../components/CustomSelect";
import HeadingWithSub from "../components/HeadingWithSub";
import styled from "@emotion/styled";
import { StoreContext } from "../store";
import { graphql } from "gatsby";
import ParentRouteHandler from "../components/ParentRouteHandler";

// maps dropdown will always have 3 levels
// 0 based levels to correspond to images array from graphql query
const mapDropdownOptions = [
  { value: 0, label: "Level 1" },
  { value: 1, label: "Level 2" },
  { value: 2, label: "Level 3" },
];

const StyledMapContainer = styled.div`
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .dropdowns {
    display: flex;
  }

  .select-container {
    min-width: 120px;
    margin-left: 10px;

    &.lang-code {
      min-width: 80px;
    }
  }

  .maps {
    min-height: 200px;

    img {
      width: 100%;
    }
  }
`;

const Map = ({ data, location }) => {
  const { currentMap, setMap } = useContext(StoreContext);
  const [isReady, setReadyState] = useState(false);

  const customMaps = data.maps.edges
    .filter(({ node }) => {
      if (node.relationships.field_mobile_map_images.length === 0) {
        return false; //skip
      }
      return true;
    })
    .map(({ node }) => {
      return {
        title: node.title,
        code: node.field_mobile_map_language_code,
        images: node.relationships.field_mobile_map_images
          .filter((el) => el.relationships.field_media_image.localFile)
          .map((el) => el.relationships.field_media_image.localFile.publicURL),
      };
    });

  const langCodeDropdownOptions = customMaps.map(({ code }) => {
    return {
      value: code,
      label: code.toUpperCase(),
    };
  });

  const setLang = (langCode) => {
    setMap({ langCode, level: 0 });
  };

  const setMapIndex = (index) => {
    const { langCode } = currentMap;
    setMap({ langCode, level: index });
  };

  const getMapImage = ({ langCode, level }) => {
    return customMaps.find((map) => map.code === langCode).images[level];
  };

  const getLangOption = (langCode) =>
    langCodeDropdownOptions.find((option) => option.value === langCode);

  const getLevelOption = (level) =>
    mapDropdownOptions.find((option) => option.value === level);

  useEffect(() => {
    let langCodeParam = location.search.split("?").pop();

    if (langCodeParam.includes("lang")) {
      langCodeParam = langCodeParam.split("=").pop();
    }

    const match = getLangOption(langCodeParam);
    if (match && currentMap.langCode !== langCodeParam) {
      setLang(langCodeParam);
    }
    // to prevent flash of previous map
    setReadyState(true);
  }, []);

  return (
    <StyledMapContainer>
      <Seo title="Map" />
      <div className="heading">
        <ParentRouteHandler title={"back"} />

        <HeadingWithSub main={"map"} />
        <div className="dropdowns">
          <div className="select-container lang-code">
            {currentMap.langCode && langCodeDropdownOptions.length > 1 && (
              <CustomSelect
                options={langCodeDropdownOptions}
                onChange={(option) => setLang(option.value)}
                value={getLangOption(currentMap.langCode)}
              />
            )}
          </div>
          <div className="select-container">
            {currentMap.level !== undefined && (
              <CustomSelect
                options={mapDropdownOptions}
                onChange={(option) => setMapIndex(option.value)}
                value={getLevelOption(currentMap.level)}
              />
            )}
          </div>
        </div>
      </div>

      <div className="maps">
        {currentMap.langCode && isReady && (
          <img src={getMapImage(currentMap)} alt="map" />
        )}
      </div>
    </StyledMapContainer>
  );
};

export default Map;

export const query = graphql`
  {
    maps: allNodeMobileMap(
      sort: { fields: field_mobile_map_language_order, order: ASC }
    ) {
      edges {
        node {
          ...MobileMapQuery
        }
      }
    }
  }
`;
